import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import MainFund from "../views/MainFund.vue";
import Forecast from "../views/Forecast.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/Home",
    name: "Home",
    component: Home,
  },  
  {
    path: "/Robot90s3",
    name: "Robot90s3",
    component: ()=>import("../views/Robot90s3"),
  },
  {
    path: "/Robot90s2",
    name: "Robot90s2",
    component: ()=>import("../views/Robot90s2"),
  },
  {
    path: "/Robot90s",
    name: "Robot90s",
    component: ()=>import("../views/Robot90s"),
  },
  {
    path: "/KnowListCompIn90s",
    name: "KnowListCompIn90s",
    component: ()=>import("../views/KnowListCompIn90s"),
  },
  {
    path: "/90s",
    name: "90s",
    component: ()=>import("../views/90s"),
  },
  {
    path: "/MainFund",
    name: "MainFund",
    component: MainFund,
  },
  {
    path: "/Forecast",
    name: "Forecast",
    component: Forecast,
  },
  {
    path: "/Template1",
    name: "Template1",
    component: ()=>import("../views/Template1.vue"),
  },
  {
    path: "/Template4",
    name: "Template4",
    component: ()=>import("../views/Template4.vue"),
  },
  {
    path: "/Template5",
    name: "Template5",
    component: ()=>import("../views/Template5.vue"),
  },
  {
    path: "/Template6",
    name: "Template6",
    component: ()=>import("../views/Template6.vue"),
  },
  {
    path: "/Template7",
    name: "Template7",
    component: ()=>import("../views/Template7.vue"),
  },
  {
    path: "/Template8",
    name: "Template8",
    component: ()=>import("../views/Template8.vue"),
  },
  {
    path: "/Template9",
    name: "Template9",
    component: ()=>import("../views/Template9.vue"),
  },
  {
    path: "/Template9-1",
    name: "Template9-1",
    component: ()=>import("../views/Template9-1.vue"),
  },
  {
    path: "/Template14",
    name: "Template14",
    component: ()=>import("../views/Template14.vue"),
  },
  {
    path: "/Template15",
    name: "Template15",
    component: ()=>import("../views/Template15.vue"),
  },
  {
    path: "/Template16",
    name: "Template16",
    component: ()=>import("../views/Template16.vue"),
  },
  {
    path: "/Template17",
    name: "Template17",
    component: ()=>import("../views/Template17.vue"),
  },
  {
    path: "/Template18",
    name: "Template18",
    component: ()=>import("../views/Template18.vue"),
  },
  {
    path: "/Template19",
    name: "Template19",
    component: ()=>import("../views/Template19.vue"),
  },
  {
    path: "/Template20",
    name: "Template20",
    component: ()=>import("../views/Template20.vue"),
  },
  {
    path: "/Template21",
    name: "Template21",
    component: ()=>import("../views/Template21.vue"),
  },
  {
    path: "/Template22",
    name: "Template22",
    component: ()=>import("../views/Template22.vue"),
  },
  {
    path: "/Template23",
    name: "Template23",
    component: ()=>import("../views/Template23.vue"),
  },
  {
    path: "/Template24",
    name: "Template24",
    component: ()=>import("../views/Template24.vue"),
  },
  {
    path: "/Template25",
    name: "Template25",
    component: ()=>import("../views/Template25.vue"),
  },
  {
    path: "/Template26",
    name: "Template26",
    component: ()=>import("../views/Template26.vue"),
  },
  {
    path: "/Template27",
    name: "Template27",
    component: ()=>import("../views/Template27.vue"),
  },
  {
    path: "/Template28",
    name: "Template28",
    component: ()=>import("../views/Template28.vue"),
  },
  {
    path: "/Template29",
    name: "Template29",
    component: ()=>import("../views/Template29.vue"),
  },
  {
    path: "/Template30",
    name: "Template30",
    component: ()=>import("../views/Template30.vue"),
  },
  {
    path: "/Template31",
    name: "Template31",
    component: ()=>import("../views/Template31.vue"),
  },
  {
    path: "/Template32",
    name: "Template32",
    component: ()=>import("../views/Template32.vue"),
  },
  {
    path: "/Template43",
    name: "Template43",
    component: ()=>import("../views/Template43.vue"),
  },
  {
    path: "/Template44",
    name: "Template44",
    component: ()=>import("../views/Template44.vue"),
  },
  {
    path: "/Template45",
    name: "Template45",
    component: ()=>import("../views/Template45.vue"),
  },
  {
    path: "/Template46",
    name: "Template46",
    component: ()=>import("../views/Template46.vue"),
  },
  {
    path: "/Template47",
    name: "Template47",
    component: ()=>import("../views/Template47.vue"),
  },
  {
    path: "/Template48",
    name: "Template48",
    component: ()=>import("../views/Template48.vue"),
  },
  {
    path: "/Template49",
    name: "Template49",
    component: ()=>import("../views/Template49.vue"),
  },
  {
    path: "/Template50",
    name: "Template50",
    component: ()=>import("../views/Template50.vue"),
  },
  {
    path: "/Template51",
    name: "Template51",
    component: ()=>import("../views/Template51.vue"),
  },
  {
    path: "/test",
    name: "test",
    component: ()=>import("../views/test.vue"),
  },
  {
    path: "/Template100",
    name: "Template100",
    component: ()=>import("../views/Template100.vue"),
  },
  {
    path: "/Template101",
    name: "Template101",
    component: ()=>import("../views/Template101.vue"),
  },
  {
    path: "/Template102",
    name: "Template102",
    component: ()=>import("../views/Template102.vue"),
  },
  {
    path: "/Newsknows",
    name: "Newsknows",
    component: ()=>import("../views/Newsknows.vue"),
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
  {
    path: "/v1/Template1",
    name: "Template1-v1",
    component: ()=>import("../views/v1/Template1.vue"),
  },
  {
    path: "/v1/Template2",
    name: "Template2-v1",
    component: ()=>import("../views/v1/Template2.vue"),
  },
  {
    path: "/v1/Template3",
    name: "Template3-v1",
    component: ()=>import("../views/v1/Template3.vue"),
  },
  {
    path: "/v1/Template4",
    name: "Template4-v1",
    component: ()=>import("../views/v1/Template4.vue"),
  },
  {
    path: "/v1/Template5",
    name: "Template5-v1",
    component: ()=>import("../views/v1/Template5.vue"),
  },
  {
    path: "/v1/Template6",
    name: "Template6-v1",
    component: ()=>import("../views/v1/Template6.vue"),
  },
  {
    path: "/v1/Template7",
    name: "Template7-v1",
    component: ()=>import("../views/v1/Template7.vue"),
  },
  {
    path: "/v1/Template8",
    name: "Template8-v1",
    component: ()=>import("../views/v1/Template8.vue"),
  },
  {
    path: "/v1/Template9",
    name: "Template9-v1",
    component: ()=>import("../views/v1/Template9.vue"),
  },
  {
    path: "/v1/Template9new",
    name: "Template9new-v1",
    component: ()=>import("../views/v1/Template9new.vue"),
  },
  {
    path: "/v1/Template99",
    name: "Template99-v1",
    component: ()=>import("../views/v1/Template99.vue"),
  },{
    path: "/v1/Template14",
    name: "Template14",
    component: ()=>import("../views/v1/Template14.vue"),
  },
  {
    path: "/v1/Template50",
    name: "Template50",
    component: ()=>import("../views/v1/Template50.vue"),
  },
  {
    path: "/v1/Template51",
    name: "Template51",
    component: ()=>import("../views/v1/Template51.vue"),
  },
  {
    path: "/v1/Template52",
    name: "Template52",
    component: ()=>import("../views/v1/Template52.vue"),
  },
  {
    path: "/v1/Template53",
    name: "Template53",
    component: ()=>import("../views/v1/Template53.vue"),
  },
  {
    path: "/v1/Template54",
    name: "Template54",
    component: ()=>import("../views/v1/Template55_2.vue"),
  },
  {
    path: "/v1/Template54_2",
    name: "Template54_2",
    component: ()=>import("../views/v1/Template55_3.vue"),
  },
  {
    path: "/v1/Template55",
    name: "Template55",
    component: ()=>import("../views/v1/Template55.vue"),
  },
  {
    path: "/NewTimes",
    name: "NewTimes",
    component: ()=>import("../views/NewTimes/index.vue"),
  },
  {
    path: "/v1/NewTimes",
    name: "NewTimes",
    component: ()=>import("../views/v1/NewTimes.vue"),
  },
  {
    path: "/v1/NewTimes2",
    name: "NewTimes2",
    component: ()=>import("../views/v1/NewTimes2.vue"),
  },
  {
    path: "/v1/Template56",
    name: "Template56",
    component: ()=>import("../views/v1/Template56.vue"),
  },
  {
    path: "/v1/Template57",
    name: "Template57",
    component: ()=>import("../views/v1/Template57.vue"),
  },
  {
    path: "/v1/Template58",
    name: "Template58",
    component: ()=>import("../views/v1/Template58.vue"),
  },
  {
    path: "/v1/Template102",
    name: "Template102",
    component: ()=>import("../views/v1/Template102.vue"),
  },
  {
    path: "/v1/Template103",
    name: "Template103",
    component: ()=>import("../views/v1/Template103.vue"),
  },
  {
    path: "/v1/industryChain",
    name: "industryChain",
    component: ()=>import("../views/v1/industryChain.vue")
  },
  {
    path: "/v1/Templateyh_1",
    name: "Templateyh_1",
    component: ()=>import("../views/v1/Templateyh_1.vue"),
  },
  {
    path: "/v1/Templateyh_2",
    name: "Templateyh_2",
    component: ()=>import("../views/v1/Templateyh_2.vue"),
  },
  {
    path: "/v1/Templateyh_3",
    name: "Templateyh_3",
    component: ()=>import("../views/v1/Templateyh_3.vue"),
  },
  {
    path: "/zhongxin",
    name: "zhongxin",
    component: ()=>import("../views/ZhongXin/index.vue"),
  },
  {
    path: "/zhongxin2",
    name: "zhongxin2",
    component: ()=>import("../views/ZhongXin2/index.vue"),
  },
  {
    path: "/changjiang",
    name: "changjiang",
    component: ()=>import("../views/changjiang/index.vue"),
  },
  {
    path: "/FOF",
    name: "FOF",
    component: ()=>import("../views/FOF/index.vue"),
  },
  {
    path: "/HBFinanceReport",
    name: "HBFinanceReport",
    component: ()=>import("../views/HBFinanceReport/index.vue"),
  },
  {
    path: "/HuaAn",
    name: "HuaAn",
    component: ()=>import("../views/HuaAn/index.vue"),
  },
  {
    path: "/YiChuang",
    name: "YiChuang",
    component: ()=>import("../views/YiChuang/index.vue"),
  },
  {
    path: "/YiChuang2",
    name: "YiChuang2",
    component: ()=>import("../views/YiChuang2/index.vue"),
  },
  {
    path: "/GuoJun",
    name: "GuoJun",
    component: ()=>import("../views/GuoJun/index.vue"),
  },
  {
    path: "/GuangFa",
    name: "GuangFa",
    component: ()=>import("../views/GuangFa/index.vue"),
  },
  {
    path: "/GuangFa2",
    name: "GuangFa2",
    component: ()=>import("../views/GuangFa2/index.vue"),
  },
  {
    path: "/GuoJunNew",
    name: "GuoJunNew",
    component: ()=>import("../views/GuoJunNew/index.vue"),
  },
  {
    path: "/v3/Template51",
    name: "Template51_v3",
    component: ()=>import("../views/v3/Template51.vue"),
  },
  {
    path: "/v3/Template9new",
    name: "Template9new_v3",
    component: ()=>import("../views/v3/Template9new.vue"),
  },
  {
    path: "/v3/Template14",
    name: "Template14_v3",
    component: ()=>import("../views/v3/Template14.vue"),
  },
  {
    path: "/v1/zhangdan",
    name: "zhangdan",
    component: ()=>import("../views/v1/zhangdan.vue"),
  },
  {
    path: "/v2/Template1",
    name: "Template1_v2",
    component: ()=>import("../views/v2/Template1.vue"),
  },
  {
    path: "/v2/Template2",
    name: "Template2_v2",
    component: ()=>import("../views/v2/Template2.vue"),
  },
  {
    path: "/v2/Template4",
    name: "Template4_v2",
    component: ()=>import("../views/v2/Template4.vue"),
  },
  {
    path: "/v2/Template9new",
    name: "Template9new_v2",
    component: ()=>import("../views/v2/Template9new.vue"),
  },
  {
    path: "/v2/Template14",
    name: "Template14_v2",
    component: ()=>import("../views/v2/Template14.vue"),
  },
  {
    path: "/v2/newtimes",
    name: "newtimes",
    component: ()=>import("../views/v2/NewTimes"),
  },
  {
    path: "/v2/template50",
    name: "template50",
    component: ()=>import("../views/v2/Template50"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
