<template>
<div class="content-template">
  <div class="card animate__animated animate__fadeIn duration" id="fcard1">
      <div class="card_box_one">
        <div class="card_box_title2 card_box_title3">
          <p class="font-blod">80家公司公布<br/>中报业绩预告</p>
          <p>超七成预喜</p>
          <p>{{dataTime}}</p>
        </div>
        <div class="width-100 height-100">
          <video
            src="video/video3.mp4"
            muted
            width="100%"
            height="100%"
            ref="video1"
          ></video>
        </div>
        <div class="logo-box">
          <img src="../assets/img/logo2.png" alt="" />
        </div>
      </div>
    </div>
  <div class="container container2">
    <div class="audio-box">
      <audio src="audio/AVeryHappyChristmas.mp3" ref='bgAudio'></audio>
      <!-- <audio :src="audioSrc" ref='audio1'></audio> -->
      <audio :src="audio1Src" ref='audio1'></audio>
      <audio :src="audio2Src" ref='audio2'></audio>
      <audio :src="audio3Src" ref='audio3'></audio>
      <audio :src="audio4Src" ref='audio4'></audio>
      <audio :src="audio5Src" ref='audio5'></audio>   
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="fcard2"
    >
      <div class="card_two_box">
        <div class="card_header">
          <span class="font-blod">沪深两市</span>
          <span class="line"></span>
          <span>业绩预告披露</span>
          <span>{{dataTime}}</span>
        </div>
        <div style="padding: 0px 18px">
          <div class="margin-t-10">
            一季报预告业绩类型(家)
          </div>
          <div id="fchart1" style="width: 100%; height: 460px"></div>
        </div>
        <div class="logo-box logo-box2">
          <img src="../assets/img/logo2.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="fcard3"
    >
      <div class="card_three_box">
        <div class="card_header">
          <span class="font-blod">沪深两市</span>
          <span class="line"></span>
          <span>业绩预告披露</span>
          <span>{{dataTime}}</span>
        </div>
        <div style="padding: 0px 18px">
          <div class="margin-t-18">
            一季报预告业绩类型
          </div>
          <div class="flex flex-between margin-t-18 notice-box">
            <p class="up-box"><i><img src="../assets/img/up.png" alt=""></i>预喜率：<span>{{updata}}%</span></p>
            <p class="down-box"><i><img src="../assets/img/down.png" alt=""></i>预悲率：<span>{{downdata}}%</span></p>
          </div>
          <div id="fchart2" style="width: 100%; height: 400px"></div>
        </div>
      </div>
      <div class="logo-box logo-box2">
        <img src="../assets/img/logo2.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="fcard4"
    >
      <div>
        <div class="card_header">
          <span class="font-blod">沪深两市</span>
          <span class="line"></span>
          <span>板块预警</span>
          <span>{{dataTime}}</span>
        </div>
        <div style="padding: 0px 18px">
         <div class="margin-t-18 font-18">
            一季报预告业绩类型(家)
          </div>
          <div id="fchart3" style="width: 100%; height: 460px"></div>
        </div>
      </div>
      <div class="logo-box logo-box2">
        <img src="../assets/img/logo2.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="fcard5"
    >
     <div class="moveTop">
        <div class="card_header">
          <span class="font-blod">沪深两市</span>
          <span class="line"></span>
          <span>板块预警</span>
          <span>{{dataTime}}</span>
        </div>
        <div>
          <div style="padding: 0px 18px" class="margin-t-18 font-18 none">
            一季报预告业绩类型(家)
          </div>
          <div class="flex data-box margin-t-18">
            <div class="left">
              <p>证券名称</p>
            </div>
            <div class="center flex1">
              <p>同比增长上限及下限(%)</p>
            </div>
            <div class="right">
              <p>所属行业</p>
            </div>
          </div>
          <div class="data-box autoy">
            <div class="flex relative moveUp">
              <div class="left">
                <p v-for="(item,index) in xdata4" :key="index">{{item}}</p>
              </div>
              <div class="center flex1">
                <p v-for="(item,index) in ydata4" :key="index" class="datapercent">{{item}}</p>
              </div>
              <div class="right">
                <p v-for="(item,index) in typedata4" :key="index">{{item}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="logo-box logo-box2">
        <img src="../assets/img/logo2.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="fcard6"
    >
     <div class="moveTop">
        <div class="card_header">
          <span class="font-blod">沪深两市</span>
          <span class="line"></span>
          <span>业绩预报披露</span>
          <span>{{dataTime}}</span>
        </div>
        <div>
          <div style="padding: 0px 18px" class="margin-t-18 font-18 none">
            15公司业绩下滑
          </div>
          <div class="flex data-box margin-t-18">
            <div class="left">
              <p>证券名称</p>
            </div>
            <div class="center flex1">
              <p>同比增长上限及下限(%)</p>
            </div>
            <div class="right">
              <p>所属行业</p>
            </div>
          </div>
          <div class="data-box autoy">
            <div class="flex relative movedown">
              <div class="left">
                <p v-for="(item,index) in xdata5" :key="index">{{item}}</p>
              </div>
              <div class="center flex1">
                <p v-for="(item,index) in ydata5" :key="index" class="datadown">{{item}}</p>
              </div>
              <div class="right">
                <p v-for="(item,index) in typedata5" :key="index">{{item}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="logo-box logo-box2">
        <img src="../assets/img/logo2.png" alt="" />
      </div>
    </div>
  </div>
  <div class="card animate__animated animate__fadeIn duration none" id="card7">
    <div class="width-100 height-100">
      <video
        class="end_video"
        src="video/end.mp4"
        muted
        width="100%"
        height="100%"
        ref="endVideo"
      ></video>
    </div>
  </div>
</div>
</template>

<script>
import $ from "jquery";
import { NumberEvent } from "../utils/number";
import { GetParams } from '../utils/common'
import ApiServe from '@/api/index'
export default {
  name: "MainFund",
  data() {
    return {
      // 一季报预告业绩类型
      audioSrc:'',
      audio1Src:"",
      audio2Src:"",
      audio3Src:"",
      audio4Src:"",
      audio5Src:"",
      xdata1: [],
      ydata1: [],
      data2:[],
      updata:null,
      downdata:null,
      xdata3:[],                //一季报预告业绩类型
      xidata3:[10,10,10,10],    //预喜
      beidata3:[5,5,5,5],       //预悲
      undata3:[1,2,2,2,2],      //不确定
      xdata4:[],
      ydata4:[],
      typedata4:[],
      xdata5:[],
      ydata5:[],
      typedata5:[],
      chart_data_1:[],
      device_id:null,
      model_id:null,
      record_time:null,
      real:1,    //0测试, 1正式
      dataTime:''
    };
  },
  created() {
    this.device_id = GetParams().device_id || null
    this.model_id = GetParams().model_id || null
    this.record_time = GetParams().record_time || 65
    this.getData();
    this.getAudio()
  },
  computed:{
    allReady(){
      const { chart_data_1, audio1Src } = this;
      return {
        chart_data_1,
        audio1Src,
      };
    }
  },
  watch:{
    allReady(val){
      if(val.chart_data_1.length>0 && val.audio1Src){
        if(this.device_id && this.real){
          setTimeout(()=>{
            ApiServe.recordStart({
                model_id: this.model_id,
                device_id: this.device_id,
                record_time: this.record_time,
                real: this.real,
              }).then(({code})=>{
                if(code == 200){
                  this.load();
                }
              }) 
          },5000)    
        }else{
           this.load();
        } 
      }
    }
  },
  methods: {
    getData() {
      ApiServe.getData({ model_id: 3 }).then(({data,code})=>{
          if(code == 200){
            this.dataTime = data.extra_param_3[0]
            this.chart_data_1 = data.chart_data_1
            this.xdata1 = data.chart_data_1[0].data.x
            this.ydata1 = data.chart_data_1[0].data.y
            // 自己组装数据
            this.data2 = data.chart_data_2[0].data.x.map((item,index)=>{
              return {
                value:Number(data.chart_data_2[0].data.y[index]),
                name:item
              }
            })
            // 数组里面后端返回的顺序不固定，只能这样取
            this.updata = this.data2.filter((item)=> item.name == '预喜')[0].value.toFixed(2)
            this.downdata = this.data2.filter((item)=> item.name == '预悲')[0].value.toFixed(2)
            // 一季报预告业绩类型
            this.xdata3 = data.chart_data_3[0].data.x
            this.xidata3 = data.chart_data_3[0].data['预喜']
            this.beidata3 = data.chart_data_3[0].data['预悲']
            this.undata3 = data.chart_data_3[0].data['不确定']
            this.xdata4 = data.chart_data_4[0].data['股票名称']
            this.ydata4 = data.chart_data_4[0].data['同比增长下限及上限']
            this.typedata4 = data.chart_data_4[0].data['所属行业']
            this.xdata5 = data.chart_data_5[0].data['股票名称']
            this.ydata5 = data.chart_data_5[0].data['同比增长下限及上限']
            this.typedata5 = data.chart_data_5[0].data['所属行业']
          }  
      }).catch((err)=>{
          if(this.device_id){
            ApiServe.releasedSevice({ device_id: this.device_id })
          }
        })
    },
    // 获取旁白
    getAudio(){
      ApiServe.getVideoData({ model_id: 3 }).then(({data,code,dd})=>{
        if(code == 200){
          //this.audioSrc = dd
          this.audio1Src = data[0].audio_link
          this.audio2Src = data[1].audio_link
          this.audio3Src = data[2].audio_link
          this.audio4Src = data[3].audio_link
          this.audio5Src = data[4].audio_link
        }
      }).catch((err)=>{
          if(this.device_id){
            ApiServe.releasedSevice({ device_id: this.device_id })
          }
        });
    },
    // 一季报预告业绩类型
    loadChart1() {
      // 基于准备好的dom，初始化this.$echarts实例
      var myChart = this.$echarts.init(document.getElementById("fchart1"));
      // 指定图表的配置项和数据
      var option = {
        grid: {
          left: "60px",
          top: "20px",
          bottom: "26px",
          right: "40px",
        },
        animationDuration: 4000,
        xAxis: [
          {
            type: "value",
            show:false,
          },
        ],
         yAxis: [
          {
            type: "category",
            data: this.xdata1,
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel:{
              color:'#AFF7FF'
            }
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 24,
            data: this.xdata1.map((item,index)=>{
              return {
                value: this.ydata1[index],
                label: {
                  // 设置显示label
                  show: true,
                  // 设置label的位置
                  position: "right",
                  // 设置label的文字颜色
                  color: '#AFF7FF',
                  // 格式化label文字
                  // formatter: function (value) {
                  //   return Math.abs(value).toFixed(2);
                  // },
                  textStyle: { //数值样式
                    fontSize: 14
                  },
                },
                itemStyle: {
                  color: (item == '预增' || item == '略增' || item == '续盈' || item == '扭亏') ? "#E54444" : (item == '略减' || item == '预减' || item == '首亏' || item == '增亏' || item == '续亏') ? "#00B466" : '#9A9A9A',
                },
              };
            }),
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },

    // 一季报预告业绩类型
    loadChart2() {
      var colorList = ['#E54444','#00B466','#9A9A9A']
      // 基于准备好的dom，初始化this.$echarts实例
      var myChart = this.$echarts.init(document.getElementById("fchart2"));
      // 指定图表的配置项和数据
      var option = {
        grid: {
          left: "60px",
          top: "20px",
          bottom: "26px",
          right: "0",
        },
        animationDuration: 4000,
        series: [
          {
            type: "pie",
            data: this.data2,
            radius: '50%',
            center: ['50%', '40%'],
            itemStyle: {
              normal: {
                label: {
                  textStyle: {
                    //数值样式
                    color: "#AFF7FF",
                    fontSize: 14,
                  },
                  formatter: function (value) {
                    return value.name+':'+'\n'+ (value.value).toFixed(2)+'%';
                  },
                },
                color:function(params){
                  return params.name == '预喜' ? colorList[0] : params.name == '预悲' ? colorList[1] :colorList[2]
                },
                labelLine:{
                  smooth:true
                }
              },
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },

    // 一季报预告业绩类型
    loadChart3() {
      // 基于准备好的dom，初始化this.$echarts实例
      var myChart = this.$echarts.init(document.getElementById("fchart3"));
      // 指定图表的配置项和数据
      var option = {
        legend: {
          data: ['预喜','预悲','不确定'],
          textStyle: {
            color: "#AFF7FF",
          },
          right:0,
          top:0,
          width:40,
          itemWidth: 10,
          itemHeight:10,
        },
        grid: {
          left: "60px",
          top: "60px",
          bottom: "26px",
          right: "50px",
        },
        animationDuration: 4000,
        xAxis: [
          {
            type: "value",
            show:false,
          },
        ],
        yAxis: [
          {
            type: "category",
            data: this.xdata3,
            axisLine: { show: true,lineStyle:{color:"#9B9B9B"} },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel:{
              color:'#AFF7FF'
            }
          },
        ],
        series: [
          {
            name: "预喜",
            type: "bar",
            data: this.xidata3,
            itemStyle: {
              normal: {
                color: "#E54444",
              },
            },
          },
          {
            name: "预悲",
            type: "bar",
            data: this.beidata3,
            itemStyle: {
              normal: {
                color: "#00B466",
              },
            },
          },
          {
            name: "不确定",
            type: "bar",
            data: this.undata3,
            itemStyle: {
              normal: {
                color: "#9A9A9A",
              },
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    load() {
      this.$refs.bgAudio.volume = 0.4     //背景音乐音量调节（1为最大）
      this.$refs.bgAudio.play()     //背景音乐播放
      this.$refs.video1.play()  //首页的视频播放
      setTimeout( ()=> {
        $("#fcard1").addClass("animate__fadeOut");
      }, 2000);
      setTimeout(() => {
        $("#fcard1").addClass("none");
        $("#fcard2").removeClass("none");
        this.loadChart1();
        this.$refs.audio1.play()
      }, 3000);
      setTimeout( ()=> {
        $("#fcard2").addClass("animate__fadeOut");
      }, 18000);
      setTimeout( ()=> {
        $("#fcard2").addClass("none");
        $("#fcard3").removeClass("none");
        this.loadChart2();
        this.$refs.audio2.play()
      }, 19000);
      setTimeout( ()=> {
        $("#fcard3").addClass("animate__fadeOut");
      }, 24000);
      setTimeout( ()=> {
        $("#fcard3").addClass("none");
        $("#fcard4").removeClass("none");
        this.loadChart3();
        this.$refs.audio3.play()
      }, 25000);
      setTimeout( ()=> {
        $("#fcard4").addClass("animate__fadeOut");
      }, 35000);
      setTimeout( ()=> {
        $("#fcard4").addClass("none");
        $("#fcard5").removeClass("none");
        if(this.xdata4.length>10){
          let topPx = (this.xdata4.length -10)*40
          $('.moveUp').animate({top:topPx},8000)
        };
        this.$refs.audio4.play()
      }, 36000);
      setTimeout( ()=> {
        $("#fcard5").addClass("animate__fadeOut");
      }, 50000);
      setTimeout( ()=> {
        $("#fcard5").addClass("none");
        $("#fcard6").removeClass("none");
        if(this.xdata5.length>10){
          let topPx = (this.xdata5.length -10)*40
          $('.movedown').animate({top:topPx},8000)
        };
        this.$refs.audio5.play()
      }, 51000);
      setTimeout( ()=> {
        $("#fcard6").addClass("animate__fadeOut");
      }, 59000);
      setTimeout( ()=> {
        $("#fcard6").addClass("none");
        $("#card7").removeClass("none");
        this.$refs.endVideo.play()
      }, 60000);
      // 总共65秒
      if(this.device_id){
        setTimeout( ()=> {
          ApiServe.recordEnd({model_id:3,device_id:this.device_id,real:this.real})
        }, 70000);  
      }
 
      
    },
  },
};
</script>