<template>
<div class="content-template">
   <div class="card animate__animated animate__fadeIn duration" id="card1">
      <div class="card_box_one height-100">
        <div class="card_box_title">
          <p class="font-blod">北上数据</p>
          <p>{{dataTime}}</p>
        </div>
        <div class="width-100 height-100">
          <video
            src="video/video1.mp4"
            muted
            width="100%"
            height="100%"
            ref="video1"
          ></video>
        </div>
        <div class="logo-box">
          <img src="../assets/img/logo2.png" alt="" />
        </div>
      </div>
    </div>
  <div class="container">
    <div class="audio-box">
      <audio src="audio/Bridge.mp3" ref='bgAudio'></audio>
      <audio :src="audio1Src" ref='audio1'></audio>
      <audio :src="audio2Src" ref='audio2'></audio>
      <audio :src="audio3Src" ref='audio3'></audio>
      <audio :src="audio4Src" ref='audio4'></audio>
      <audio :src="audio5Src" ref='audio5'></audio>      
    </div>
   
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card2"
    >
      <div class="card_two_box height-100">
        <div class="card_header">
          <span class="font-blod">北上资金</span>
          <span class="line"></span>
          <span class="vertical-top font-18">成交概况</span>
          <span>{{dataTime}}</span>
        </div>
        <div style="padding: 0px 18px" >
          <div style="padding: 10px 0">
            北上资金净流入
            <div id="data1" class="numerbox inline-block" style="margin-bottom: 2.5px;"></div>
            亿元
          </div>
          <div class="relative">
            <div class="chart-left text-center">
              <p>买入成交额</p>
              <p>(亿元)</p>
            </div>
            <div id="chart1" style="width: 100%; height: 130px"></div>
          </div>
          <div class="relative margin-t-10">
            <div class="chart-left-top text-center">
              <p>卖出成交额</p>
              <p>(亿元)</p>
            </div>
            <div id="chart2" style="width: 100%; height: 130px"></div>
          </div>
          <div class="relative margin-t-10">
            <div class="chart-left-center text-center">
              <p>净买额</p>
              <p>(亿元)</p>
            </div>
            <div id="chart3" style="width: 100%; height: 130px"></div>
          </div>
        </div>
        <div class="logo-box logo-box2">
          <img src="../assets/img/logo2.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card3"
    >
      <div class="card_three_box height-100">
        <div class="card_header">
          <span class="font-blod">北上资金</span>
          <span class="line"></span>
          <span class="vertical-top font-18">成交概况</span>
          <span>{{dataTime}}</span>
        </div>
        <div style="padding: 0px 18px">
          <div style="padding: 10px 0">
            近30日北上资金累计净买额
            <div id="data2" class="numerbox inline-block"></div>
            亿元
          </div>
          <div id="chart4" style="width: 100%; height: 420px"></div>
        </div>
      </div>
      <div class="logo-box logo-box2">
        <img src="../assets/img/logo2.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card4"
    >
      <div class="height-100">
        <div class="card_header">
          <span class="font-blod">北上资金</span>
          <span class="line"></span>
          <span class="vertical-top font-18">10大活跃股票</span>
          <span>{{dataTime}}</span>
        </div>
        <div class="flex flex-between margin-18">
          <p class="font-18">沪股通</p>
          <p class="text-center">净买额<br />(亿元)</p>
        </div>
        <div class="relative">
          <div id="chart5" style="width: 100%; height: 380px"></div>
          <div class="data-number data-number1"></div>
        </div>
      </div>
      <div class="logo-box logo-box2">
        <img src="../assets/img/logo2.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card5"
    >
      <div class="height-100">
        <div class="card_header">
          <span class="font-blod">北上资金</span>
          <span class="line"></span>
          <span class="vertical-top font-18">10大活跃股票</span>
          <span>{{dataTime}}</span>
        </div>
        <div class="flex flex-between margin-18">
          <p class="font-18">深股通</p>
          <p class="text-center">净买额<br />(亿元)</p>
        </div>
        <div class="relative">
          <div id="chart6" style="width: 100%; height: 380px"></div>
          <div class="data-number data-number2"></div>
        </div>
      </div>
      <div class="logo-box logo-box2">
        <img src="../assets/img/logo2.png" alt="" />
      </div>
    </div>
    <!-- <div
      class="card animate__animated animate__fadeIn duration none"
      id="card6"
    >
      <div class="height-100">
        <div class="card_header">
          <span class="font-blod">北上资金</span>
          <span class="line"></span>
          <span class="vertical-top font-18">持股比例居前个股</span>
          <span>{{dataTime}}</span>
        </div>
        <div class="flex flex-between margin-18">
          <p class="font-18">股票代码</p>
          <p class="text-center">占比<br />(%)</p>
        </div>
        <div class="relative">
          <div id="chart7" style="width: 100%; height: 380px"></div>
          <div class="data-number data-number3"></div>
        </div>
        <div class="logo-box logo-box2">
          <img src="../assets/img/logo2.png" alt="" />
        </div>
      </div>
    </div> -->
  </div>
  <div class="card animate__animated animate__fadeIn duration none" id="card6">
    <div class="width-100 height-100">
      <video
        class="end_video"
        src="video/end.mp4"
        muted
        width="100%"
        height="100%"
        ref="endVideo"
      ></video>
    </div>
  </div>
</div>
</template>

<script>
import $ from "jquery";
import { NumberEvent } from "../utils/number";
import { GetParams } from '../utils/common'
import ApiServe from '@/api/index'
export default {
  name: "Home",
  data() {
    return {
      // dataTime:localStorage.getItem('dataTime'),
      dataTime:"",
      //week:localStorage.getItem('week'),
      // 买入图表的数据
      xdata1: [],
      ydata1: [],
      // 卖出成交额图表
      ydata2: [],

      // 净买额图表
      ydata3: [],

      // 近30日北上资金累计净买额图表
      lengedData4: ["沪股通净流入额", "深股通净流入额", "合计流入额"],
      xdata4: [],
      hgDta4: [],
      sgData4: [],
      totalData4: [],
      // 北上资金10大活跃股票
      // 沪股
      ydata5: [],
      seriesData5 : [],
      // 深股
      ydata6: [],
      seriesData6 : [],
      // 比例居前个股
      ydata7: [],
      seriesData7: [],
      extra_param_1:null,
      extra_param_2:null,
      audio1Src:"",
      audio2Src:"",
      audio3Src:"",
      audio4Src:"",
      audio5Src:"",
      device_id:null,
      model_id:null,
      record_time:null,
      real:1    //0测试, 1正式
    };
  },
  created() {
    this.device_id = GetParams().device_id || null
    this.model_id = GetParams().model_id || null
    this.record_time = GetParams().record_time || 40
    this.getData();
    this.getAudio()
  },
  computed:{
    allReady(){
      const { extra_param_1, audio1Src } = this;
      return {
        extra_param_1,
        audio1Src,
      };
    }
  },
  watch:{
    allReady(val){
      if( Math.abs(val.extra_param_1) && val.audio1Src){
        if(this.device_id && this.real){
          setTimeout(()=>{
              ApiServe.recordStart({
                model_id: this.model_id,
                device_id: this.device_id,
                record_time: this.record_time,
                real: this.real,
              }).then(({code})=>{
                if(code == 200){
                  this.load();
                }
              }) 
          },5000)    
        }else{
          setTimeout(()=>{
            this.load();
          },2000)
          // this.load();
        } 
      }
    }
  },
  methods: {
    getData() {
      ApiServe.getData({ model_id: 1 }).then(({data,code})=>{
          if(code == 200){
            this.dataTime = data.extra_param_3[0]
            this.extra_param_1 = data.extra_param_1[0]
            this.extra_param_2 = data.extra_param_2[0]
            this.xdata1 =  data.chart_data_1[0].data.x
            this.ydata1 =  data.chart_data_1[0].data.y
            this.xdata2 =  data.chart_data_2[0].data.x
            this.ydata2 =  data.chart_data_2[0].data.y.map((n)=>{
              return -n
            })
            this.xdata3 =  data.chart_data_3[0].data.x
            this.ydata3 =  data.chart_data_3[0].data.y
            this.xdata4 =  data.chart_data_4[0].data.x
            this.hgDta4 =  data.chart_data_4[0].data.y1
            this.sgData4 =  data.chart_data_4[0].data.y2
            this.totalData4 =  data.chart_data_4[0].data.y3
            this.ydata5 =  data.chart_data_5[0].data.x
            this.seriesData5 =  data.chart_data_5[0].data.y.map((n)=>{
              return Math.floor((n /100000000) * 100) / 100
            })
            this.ydata6 =  data.chart_data_6[0].data.x
            this.seriesData6 =  data.chart_data_6[0].data.y.map((n)=>{
              return Math.floor((n /100000000) * 100) / 100
            })
            // this.ydata7 =  data.chart_data_7[0].data.x
            // this.seriesData7 =  data.chart_data_7[0].data.y
          }
          
      }).catch((err)=>{
          if(this.device_id){
            ApiServe.releasedSevice({ device_id: this.device_id })
          }
        })
    },
    // 获取旁白
    getAudio(){
      ApiServe.getVideoData({ model_id: 1 }).then(({data,code})=>{
        if(code == 200){
          this.audio1Src = data[0].audio_link
          this.audio2Src = data[1].audio_link
          this.audio3Src = data[2].audio_link
          this.audio4Src = data[3].audio_link
          this.audio5Src = data[4].audio_link
        }
      }).catch((err)=>{
          if(this.device_id){
            ApiServe.releasedSevice({ device_id: this.device_id })
          }
        });
    },
    // 买入成交额图表
    loadChart1() {
      // 基于准备好的dom，初始化this.$echarts实例
      var myChart = this.$echarts.init(document.getElementById("chart1"));
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        grid: {
          left: "30%",
          top: "20px",
          bottom: "26px",
          right: "10px",
        },
        animationDuration: 4000,
        xAxis: [
          {
            type: "category",
            data: this.xdata1,
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#AFF7FF",
              },
            },
            axisTick: {
              show: false
            },
          },
        ],
        yAxis: [
          {
            show: false,
          },
        ],
        series: [
          {
            name: "成交额",
            type: "bar",
            data: this.ydata1,
            barWidth: 24,
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#E54444",
                    fontSize: 14,
                  },
                  formatter:function(value){
                    return Math.abs(value.value).toFixed(2)
                  }
                },
                color: "#E54444",
              },
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },

    // 卖出成交额图表
    loadChart2() {
      // 基于准备好的dom，初始化this.$echarts实例
      var myChart = this.$echarts.init(document.getElementById("chart2"));
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        grid: {
          left: "30%",
          top: 0,
          bottom: "10%",
          right: "10px",
        },
        animationDuration: 4000,
        xAxis: [
          {
            type: "category",
            data: this.xdata1,
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: false
            },
          },
        ],
        yAxis: [
          {
            show: false,
          },
        ],
        series: [
          {
            name: "成交额",
            type: "bar",
            data: this.ydata2,
            barWidth: 24,
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "bottom", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#00B466",
                    fontSize: 14,
                  },
                  formatter: function (value) {
                    return Math.abs(value.value).toFixed(2);
                  },
                },
                color: "#00B466",
              },
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },

    // 净买额图表
    loadChart3() {
      // 基于准备好的dom，初始化this.$echarts实例
      var myChart = this.$echarts.init(document.getElementById("chart3"));
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        grid: {
          left: "30%",
          top: "20px",
          bottom: "26px",
          right: "10px",
        },
        animationDuration: 4000,
        xAxis: [
          {
            type: "category",
            data: this.xdata1,
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: false
            },
          },
        ],
        yAxis: [
          {
            show: false,
          },
        ],
        series: [
          {
            name: "成交额",
            type: "bar",
            barWidth: 24,
            data: this.ydata3.map((item) => {
              return {
                value: item,
                label: {
                  // 设置显示label
                  show: true,
                  // 设置label的位置
                  position: item > 0 ? "top" : "bottom",
                  // 设置label的文字颜色
                  color: item > 0 ? "#E54444" : "#00B466",
                  // 格式化label文字
                  formatter: function (value) {
                    return Math.abs(value.value).toFixed(2);
                  },
                  textStyle: { //数值样式
                    fontSize: 14
                  },
                },
                itemStyle: {
                  color: item > 0 ? "#E54444" : "#00B466",
                },
              };
            }),
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },

    // 近30日北上资金累计净买额图表
    loadChart4() {
      // 基于准备好的dom，初始化this.$echarts实例
      var myChart = this.$echarts.init(document.getElementById("chart4"));
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        legend: {
          data: this.lengedData4,
          textStyle: {
            color: "#AFF7FF",
          },
          itemWidth: 10,
        },
        grid: {
          left: "10%",
          right:0,
          top: "40px",
          bottom: "20px",
        },
        animationDuration: 4000,
        xAxis: [
          {
            type: "category",
            data: this.xdata4,
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#AFF7FF",
              },
            },
          },
        ],
        yAxis: [
          {
            show: true,
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#AFF7FF",
              },
            },
            splitLine: { 
              lineStyle:{
                type:'dashed', //设置网格线类型 dotted：虚线 solid:实线
                color:'rgba(255,255,255,0.2)'
              },
              
            },
          },
        ],
        series: [
          {
            name: "沪股通净流入额",
            type: "bar",
            data: this.hgDta4,
            itemStyle: {
              normal: {
                color: "#00D1E1",
              },
            },
          },
          {
            name: "深股通净流入额",
            type: "bar",
            data: this.sgData4,
            itemStyle: {
              normal: {
                color: "#006EEB",
              },
            },
          },
          {
            name: "合计流入额",
            type: "line",
            data: this.totalData4,
            itemStyle: {
              normal: {
                color: "#E54444",
              },
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },

    // 北上资金10大活跃股票
    loadChart5() {
      // 基于准备好的dom，初始化this.$echarts实例
      var myChart = this.$echarts.init(document.getElementById("chart5"));
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        grid: {
          left: "18px",
          right: "67px",
          top: "10px",
          bottom: "10px",
        },
        animationDuration: 4000,
        xAxis: [
          {
            type: "value",
            show: false,
          },
        ],
        yAxis: [
          {
            type: "category",
            inverse: true,
            data: this.ydata5,
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: {
              verticalAlign: "bottom",
              align: "left",
              padding: [0, 10, 10, 10],
              textStyle: {
                fontSize: 14,
                color: "#00D1E1",
              },
              formatter(value, index) {
                return value;
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            data: this.seriesData5,
            barWidth: 12,
            barGap: "40px",
            stack: "total",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(110, 193, 244, 0.2)",
            },
            itemStyle: {
              normal: {
                // color: "#00D1E1",
                color:function(params){
                  if(params.data>=0){
                    return '#E54444'
                  }else{
                    return '#00B466'
                  }
                }
                // label: {
                //   show: true, //开启显示
                //   position: 'right', //在上方显示
                //   textStyle: { //数值样式
                //     color: '#00D1E1',
                //     fontSize: 16
                //   }
                // },
              },
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },

    // 北上资金10大活跃股票--深股通
    loadChart6() {
      // 基于准备好的dom，初始化this.$echarts实例
      var myChart = this.$echarts.init(document.getElementById("chart6"));
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        grid: {
          left: "18px",
          right: "67px",
          top: "10px",
          bottom: "10px",
        },
        animationDuration: 4000,
        xAxis: [
          {
            type: "value",
            show: false,
          },
        ],
        yAxis: [
          {
            type: "category",
            inverse: true,
            data: this.ydata6,
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: {
              verticalAlign: "bottom",
              align: "left",
              padding: [0, 10, 10, 10],
              textStyle: {
                fontSize: 14,
                color: "#AFF7FF",
              },
              formatter(value, index) {
                return value;
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            data: this.seriesData6,
            stack: "total",
            barWidth: 12,
            barGap: "40px",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(110, 193, 244, 0.2)",
            },
            itemStyle: {
              normal: {
                // color: "#E19E00",
                color:function(params){
                  if(params.data>=0){
                    return '#E54444'
                  }else{
                    return '#00B466'
                  }
                }
                // label: {
                //   show: true, //开启显示
                //   position: 'right', //在上方显示
                //   textStyle: { //数值样式
                //     color: '#E19E00',
                //     fontSize: 16
                //   }
                // },
              },
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },

    // 北上资金持股比例居前个股
    loadChart7() {
      // 基于准备好的dom，初始化this.$echarts实例
      var myChart = this.$echarts.init(document.getElementById("chart7"));
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        grid: {
          left: "18px",
          right: "67px",
          top: "10px",
          bottom: "10px",
        },
        animationDuration: 4000,
        xAxis: {
          type: "value",
          show: false,
        },
        yAxis: [
          {
            type: "category",
            inverse: true,
            data: this.ydata7,
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: {
              verticalAlign: "bottom",
              align: "left",
              padding: [0, 10, 10, 10],
              textStyle: {
                fontSize: 14,
                color: "#AFF7FF",
              },
              formatter(value, index) {
                return value;
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            data: this.seriesData7,
            stack: "total",
            barWidth: 12,
            barGap: "40px",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(110, 193, 244, 0.2)",
            },
            itemStyle: {
              normal: {
                color: "#E54444",
                // label: {
                //   show: true, //开启显示
                //   position: 'right', //在上方显示
                //   textStyle: { //数值样式
                //     color: '#E54444',
                //     fontSize: 16
                //   }
                // },
              },
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    loadNumber1(num) {
      var data1 = NumberEvent.number(num);
      $("#data1").html(NumberEvent.dom(data1));
      NumberEvent.animation();
    },

    loadNumber2(num) {
      var data2 = NumberEvent.number(num);
      $("#data2").html(NumberEvent.dom(data2));

      NumberEvent.animation();
    },

    dataNumber(arr, idName, name) {
      for (let i = 0; i < arr.length; i++) {
        let str = `<p id="datanumber${name}${i}" class="numerbox">${arr[i]}</p>`;
        $(idName).append(str);
      }
      for (let i = 0; i < arr.length; i++) {
        var datanum = NumberEvent.number(String(arr[i]));
        let par = `#datanumber${name}${i}`;
        $(par).html(NumberEvent.dom(datanum));
        NumberEvent.animation();
      }
    },
    startPlay(){
      this.$refs.bgAudio.volume = 0.4     //背景音乐音量调节（1为最大）
      this.$refs.bgAudio.play()     //背景音乐播放
    },
    endPlay(){

    },
    load() {
      this.startPlay()  
      this.$refs.video1.play()  //首页的视频播放 
      setTimeout( ()=> {
        $("#card1").addClass("animate__fadeOut");
      }, 2000);
      setTimeout(() => {
        $("#card1").addClass("none");
        $("#card2").removeClass("none");
        this.loadNumber1(this.extra_param_1);
        this.loadChart1();
        this.loadChart2();
        this.loadChart3();
        this.$refs.audio1.play()
      }, 3000);          //间隔
      setTimeout( ()=> {
        // $("#card2").removeClass("animate__fadeInLeft");
        $("#card2").addClass("animate__fadeOut");
      }, 12000);
      setTimeout( ()=> {
        $("#card2").addClass("none");
        $("#card3").removeClass("none");
        this.loadNumber2(this.extra_param_2);
        this.$refs.audio2.play()
        this.loadChart4();
      }, 13000);     //间隔
      setTimeout( ()=> {
        // $("#card2").removeClass("animate__fadeInLeft");
        $("#card3").addClass("animate__fadeOut");
      }, 18000);
      setTimeout( ()=> {
        $("#card3").addClass("none");
        $("#card4").removeClass("none");
        this.loadChart5();
        this.dataNumber(this.seriesData5, ".data-number1", "hu");
        this.$refs.audio3.play()
      }, 19000);   //间隔
      setTimeout( ()=> {
        // $("#card2").removeClass("animate__fadeInLeft");
        $("#card4").addClass("animate__fadeOut");
      }, 27000);
      setTimeout( ()=> {
        $("#card4").addClass("none");
        $("#card5").removeClass("none");
        this.loadChart6();
        this.dataNumber(this.seriesData6, ".data-number2", "sh");
        this.$refs.audio4.play()
      }, 28000);   //间隔
      setTimeout( ()=> {
        // $("#card2").removeClass("animate__fadeInLeft");
        $("#card5").addClass("animate__fadeOut", "pe");
      }, 34000);
      setTimeout( ()=> {
        $("#card5").addClass("none");
        $("#card6").removeClass("none");
        // this.loadChart7();
        // this.dataNumber(this.seriesData7, ".data-number3");
        // this.$refs.audio5.play()
        this.$refs.endVideo.play()
      }, 35000);   //间隔
      // 总时间40秒
      if(this.device_id){
        setTimeout( ()=> {
          ApiServe.recordEnd({model_id:1,device_id:this.device_id,real:this.real})
        }, 45000); 
      }
  
    },
  },
};
</script>