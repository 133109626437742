import CryptoJS from "crypto-js";

const key = CryptoJS.enc.Utf8.parse("1234512345123451"); //16位
const iv = CryptoJS.enc.Utf8.parse("5432154321543215");

// import moment from "moment";
/*截取地址栏中的参数所调用的函数*/
export const GetParams = () => {
  let strs;
  var url = location.href;
  var theRequest = new Object();
  if (url.indexOf("?") != -1) {
    var str = url.split("?")[1];
    strs = str.split("&");
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
    }
  }
  return theRequest;
};

/*异步等待一段时间*/
export function sleep(seconds) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, seconds);
  });
}

export const GetParams2 = () => {
  let strs;
  var url = decodeURIComponent(location.href);
  var theRequest = new Object();
  if (url.indexOf("?") != -1) {
    var str = url.split("?")[1];
    strs = str.split("&");
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
    }
  };
  return theRequest;
};

export const getTime = () => {
  let date = new Date();
  let nowYear = date.getFullYear();
  let nowMonth = date.getMonth() + 1;
  let nowDate = date.getDate();
  let day;
  switch (new Date().getDay()) {
    case 0:
      day = "天";
      break;
    case 1:
      day = "一";
      break;
    case 2:
      day = "二";
      break;
    case 3:
      day = "三";
      break;
    case 4:
      day = "四";
      break;
    case 5:
      day = "五";
      break;
    case 6:
      day = "六";
  }
  const str = `${nowYear}-${nowMonth}-${nowDate}`;
  localStorage.setItem("dataTime", str);
  localStorage.setItem("week", day);
};

//  处理数据日报模板的日k图的5日，10日数据等
export const calculateMA = function (dayCount, data) {
  var result = [];
  for (var i = 0, len = data.values.length; i < len; i++) {
    if (i < dayCount) {
      result.push("-");
      continue;
    }
    var sum = 0;
    for (var j = 0; j < dayCount; j++) {
      sum += data.values[i - j][1];
    }
    result.push(+(sum / dayCount).toFixed(3));
  }
  return result;
};

export function formatToDate(date, flag = "-") {
  return date ? date.replace(/[年|月]/g, flag).replace("日", "") : "";
}

// 数值滚动
export const numberRoll = function (vdom, number, point = 2, timeDelay = 1) {
  const options = {
    useEasing: true, // 过渡动画效果，默认ture
    useGrouping: false, // 千分位效果，例：1000->1,000。默认true
    //separator: ',',   // 使用千分位时分割符号
    decimals: 2, // 小数位分割符号
    prefix: "", // 前置符号
    suffix: "", // 后置符号，可汉字
  };
  // vdom支持css的id选择器 ;  point  小数位数 ;  timeDelay 数字滚动几秒
  let num = new CountUp(vdom, 0, number, point, timeDelay, options);
  num.start();
};

// 防抖节流
export const throttle = function(fn, delay, isDebounce) {
  let timer
  let lastCall = 0
  return function (...args) {
    if (isDebounce) {
      if (timer) clearTimeout(timer)
      timer = setTimeout(() => {
        fn(...args)
      }, delay)
    } else {
      const now = new Date().getTime()
      if (now - lastCall < delay) return
      lastCall = now
      fn(...args)
    }
  }
}

//aes加密
export function encrypt(word) {
  let encrypted = "";
  if (typeof word == "string") {
    const srcs = CryptoJS.enc.Utf8.parse(word);
    encrypted = CryptoJS.AES.encrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
  } else if (typeof word == "object") {
    //对象格式的转成json字符串
    const data = JSON.stringify(word);
    const srcs = CryptoJS.enc.Utf8.parse(data);
    encrypted = CryptoJS.AES.encrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
  }
  return encrypted.ciphertext.toString();
}
// aes解密
export function decrypt(word) {
  const encryptedHexStr = CryptoJS.enc.Hex.parse(word);
  const srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  const decrypt = CryptoJS.AES.decrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
}

// 打字机效果
export class myPrinto{
  constructor(speed){
    this.i = 0
    this.str = ''
    this.newStr = ''
    this.timer = null
    this.speed = speed || 60
    this.isFinish=false
    this.isPause=false
  }
  init(str){
    this.i = 0
    this.isFinish=false
    this.newStr = ''
    this.timer = null
    this.str = str
    this.isPause = false
    this.play()
  }
  play(){
    this.isPause=false
    this.timer = setInterval(()=>{
      if(this.i<=this.str.length){
        if(!this.isPause){
          this.newStr = this.str.slice(0,this.i++)
        }
      }else{
        this.isFinish=true
        clearInterval(this.timer)
      } 
    },this.speed)
  }
  pause(){
    this.isPause=true
    clearInterval(this.timer)
  }
}

export function randomNum(minNum, maxNum) {
  switch (arguments.length) {
    case 1:
      return parseInt(Math.random() * minNum + 1, 10);
      break;
    case 2:
      return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
      break;
    default:
      return 0;
      break;
  }
}

//获得数组前num之和
Array.prototype.getNumArrayTotal = function (num) {
  var total = this.reduce(function (pre, cur, index, arr) {
    if (index > num - 1) {
      return pre + 0;
    }
    return pre + cur;
  });
  return total;
};

export function rowToColumn(data) {
  if (!data) return [];
  const keys = Object.keys(data).filter((key) => Array.isArray(data[key]));
  if (keys.length === 0) return [];
  const len = data[keys[0]].length;
  return Array.from({ length: len }).map((_, index) => {
    return keys.reduce((t, key) => {
      t[key] = data[key] && data[key][index];
      return t;
    }, {});
  });
}