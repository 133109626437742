<template>
<div class="content-template">
  <div class="card animate__animated animate__fadeIn duration" id="card1">
      <div class="card_box_one">
        <div class="card_box_title2">
          <p class="font-blod">主力资金</p>
          <p>日内追踪</p>
          <p>{{dataTime}}</p>
        </div>
        <div class="width-100 height-100">
          <video
            src="video/video2.mp4"
            muted
            width="100%"
            height="100%"
            ref="video1"
          ></video>
        </div>
        <div class="logo-box">
          <img src="../assets/img/logo2.png" alt="" />
        </div>
      </div>
    </div>
  <div class="container container2">
    <div class="audio-box">
      <audio src="audio/EveryoneIsSoAlive.mp3" ref='bgAudio'></audio>
    </div>
    
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card2"
    >
      <div class="card_two_box">
        <div class="card_header">
          <span class="font-blod">主力资金净流入个股一览</span>
          <span>{{dataTime}}</span>
        </div>
        <div style="padding: 0px 36px">
          <div class="flex flex-between margin-t-10">
            <p>时间：<span>{{timeChange}}</span></p>
            <p>(亿元)</p>
          </div>
          <div id="chart1" style="width: 100%; height: 460px"></div>
        </div>
        <div class="logo-box logo-box2">
          <img src="../assets/img/logo2.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card3"
    >
      <div class="card_three_box">
        <div class="card_header">
          <span class="font-blod">主力资金净流出个股一览</span>
          <span>{{dataTime}}</span>
        </div>
        <div style="padding: 0px 36px">
          <div class="flex flex-between margin-t-10">
            <p>时间：<span>{{timeChangeOut}}</span></p>
            <p>(亿元)</p>
          </div>
          <div id="chart2" style="width: 100%; height: 460px"></div>
        </div>
      </div>
      <div class="logo-box logo-box2">
        <img src="../assets/img/logo2.png" alt="" />
      </div>
    </div>
  </div>
  <div class="card animate__animated animate__fadeIn duration none" id="card4">
    <div class="width-100 height-100">
      <video
        class="end_video"
        src="video/end.mp4"
        muted
        width="100%"
        height="100%"
        ref="endVideo"
      ></video>
    </div>
  </div>
</div>
</template>

<script>
import $ from "jquery";
import { GetParams } from '../utils/common'
import ApiServe from '@/api/index'
export default {
  name: "MainFund",
  data() {
    return {
      // 主力资金净流入
      dataTime:'',
      timeChange:'9:30',
      timeChangeOut:'9:30',
      chart_data_1:[],
      xdata1: [],
      ydata1: [],
      chart_data_2:[],
      // 主力资金净流出
      xdata2: [],
      ydata2: [],

      // 龙虎榜营业部净买入
      xdata3: [],
      ydata3: [],
      // 龙虎榜机构净买入
      xdata4: [],
      ydata4: [],
      audio1Src:"",
      audio2Src:"",
      audio3Src:"",
      audio4Src:"",
      audio5Src:"",
      device_id:null,
      model_id:null,
      record_time:null,
      real:1    //0测试, 1正式
    };
  },
  created() {
    this.device_id = GetParams().device_id || null
    this.model_id = GetParams().model_id || null
    this.record_time = GetParams().record_time || 34
    this.getData();
    //this.getAudio()
  },
  computed:{
    allReady(){
      const { chart_data_1, audio1Src } = this;
      return {
        chart_data_1,
        audio1Src,
      };
    }
  },
  watch:{
    allReady(val){
      // val.chart_data_1 && val.audio1Src
      if(val.chart_data_1.length>0){
        console.log('两个接口数据都准备好了')
        if(this.device_id && this.real){
          setTimeout(()=>{
            ApiServe.recordStart({
                model_id: this.model_id,
                device_id: this.device_id,
                record_time: this.record_time,
                real: this.real,
              }).then(({code})=>{
              if(code == 200){
                 this.load();
              }
            }) 
          },5000)    
        }else{
          this.load();
        } 
      }
    }
  },
  methods: {
    getData() {
      ApiServe.getData({ model_id: 2 }).then(({data,code})=>{
          if(code == 200){
            this.dataTime = data.extra_param_1[0]
            this.chart_data_1 = data.chart_data_1
            this.xdata1 = data.chart_data_1[0].data.x || []
            this.ydata1 = (data.chart_data_1[0].data.y || []).map((n)=>{
              return Math.floor((n /100000000) * 100) / 100
            })
            this.chart_data_2 = data.chart_data_2
            this.xdata2 = data.chart_data_2[0].data.x || []
            this.ydata2 = (data.chart_data_2[0].data.y || []).map((n)=>{
              return Math.floor((n /100000000) * 100) / 100
            })
          }  
      }).catch((err)=>{
          if(this.device_id){
            ApiServe.releasedSevice({ device_id: this.device_id })
          }
        })
    },
    // 获取旁白
    getAudio(){
      ApiServe.getVideoData({ model_id: 2 }).then(({data,code})=>{
        if(code == 200){
          this.audio1Src = data[0].audio_link
          this.audio2Src = data[1].audio_link
          this.audio3Src = data[2].audio_link
          this.audio4Src = data[3].audio_link
          this.audio5Src = data[4].audio_link
        }
      }).catch((err)=>{
          if(this.device_id){
            ApiServe.releasedSevice({ device_id: this.device_id })
          }
        });
    },
    // 主力资金净流入个股一览
    loadChart1() {
      // 基于准备好的dom，初始化this.$echarts实例
      var myChart = this.$echarts.init(document.getElementById("chart1"));
      // 指定图表的配置项和数据
      var option = {
        grid: {
          left: "60px",
          top: "20px",
          bottom: "26px",
          right: "0",
        },
        animationDuration: 2000,
        xAxis: [
          {
            type: "value",
            show:false,
          },
        ],
         yAxis: [
          {
            type: "category",
            data: this.xdata1,
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel:{
              color:'#AFF7FF'
            }
          },
        ],
        series: [
          {
            type: "bar",
            data: this.ydata1,
            barWidth: 24,
            barMinHeight:50,
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "right", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#AFF7FF",
                    fontSize: 14,
                  },
                  offset:[-40,0],
                  formatter:function(value){
                    return Math.abs(value.value).toFixed(2)
                  }
                },
                color: "#E54444",
              },
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },

    // 主力资金净流出个股一览
    loadChart2() {
      // 基于准备好的dom，初始化this.$echarts实例
      var myChart = this.$echarts.init(document.getElementById("chart2"));
      // 指定图表的配置项和数据
      var option = {
        grid: {
          left: "60px",
          top: "20px",
          bottom: "26px",
          right: "0",
        },
        animationDuration: 4000,
        xAxis: [
          {
            type: "value",
            show:false,
          },
        ],
        yAxis: [
          {
            type: "category",
            data: this.xdata2,
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel:{
              color:'#AFF7FF'
            }
          },
        ],
        series: [
          {
            type: "bar",
            data: this.ydata2,
            barWidth: 24,
            barMinHeight:50,
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "right", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#ffffff",
                    fontSize: 14,
                  },
                  offset:[-40,0],
                  formatter: function (value) {
                    return (-value.value).toFixed(2);
                  },
                },
                color: "#00B466",
              },
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    load() {
      this.$refs.bgAudio.volume = 0.4     //背景音乐音量调节（1为最大）
      this.$refs.bgAudio.play()     //背景音乐播放
      this.$refs.video1.play()  //首页的视频播放
      setTimeout( ()=> {
        $("#card1").addClass("animate__fadeOut");
      }, 2000);
      setTimeout(() => {
        $("#card1").addClass("none");
        $("#card2").removeClass("none");
        this.loadChart1();
      }, 3000);
      setTimeout(()=>{
        this.timeChange = '10:00'
        this.xdata1 = this.chart_data_1[1].data.x || []
        this.ydata1 = (this.chart_data_1[1].data.y || []).map((n)=>{
          return Math.floor((n /100000000) * 100) / 100
        })
        this.loadChart1();
      },5000)
         setTimeout(()=>{
        this.timeChange = '11:00'
        this.xdata1 = this.chart_data_1[2].data.x || []
        this.ydata1 = (this.chart_data_1[2].data.y || []).map((n)=>{
          return Math.floor((n /100000000) * 100) / 100
        })
        this.loadChart1();
      },7000)
      setTimeout(()=>{
        this.timeChange = '11:30'
        this.xdata1 = this.chart_data_1[3].data.x || []
        this.ydata1 = (this.chart_data_1[3].data.y || []).map((n)=>{
          return Math.floor((n /100000000) * 100) / 100
        })
        this.loadChart1();
      },9000)
       setTimeout(()=>{
        this.timeChange = '14:00'
        this.xdata1 = this.chart_data_1[4].data.x || []
        this.ydata1 = (this.chart_data_1[4].data.y || []).map((n)=>{
          return Math.floor((n /100000000) * 100) / 100
        })
        this.loadChart1();
      },11000)
      setTimeout(()=>{
        this.timeChange = '15:00'
        this.xdata1 = this.chart_data_1[5].data.x || []
        this.ydata1 = (this.chart_data_1[5].data.y || []).map((n)=>{
          return Math.floor((n /100000000) * 100) / 100
        })
        this.loadChart1();
      },13000)
      setTimeout( ()=> {
        $("#card2").addClass("animate__fadeOut");
      }, 15000);
      setTimeout( ()=> {
        $("#card2").addClass("none");
        $("#card3").removeClass("none");
        this.loadChart2();
      }, 16000);
      setTimeout( ()=> {
        this.timeChangeOut = '10:00'
        this.xdata2 = this.chart_data_2[1].data.x || []
        this.ydata2 = (this.chart_data_2[1].data.y || []).map((n)=>{
          return Math.floor((n /100000000) * 100) / 100
        })
        this.loadChart2();
      }, 18000);
      setTimeout( ()=> {
        this.timeChangeOut = '11:00'
        this.xdata2 = this.chart_data_2[2].data.x || []
        this.ydata2 = (this.chart_data_2[2].data.y || []).map((n)=>{
          return Math.floor((n /100000000) * 100) / 100
        })
        this.loadChart2();
      }, 20000);
      setTimeout( ()=> {
        this.timeChangeOut = '11:30'
        this.xdata2 = this.chart_data_2[3].data.x || []
        this.ydata2 = (this.chart_data_2[3].data.y || []).map((n)=>{
          return Math.floor((n /100000000) * 100) / 100
        })
        this.loadChart2();
      }, 22000);
      setTimeout( ()=> {
        this.timeChangeOut = '14:00'
        this.xdata2 = this.chart_data_2[4].data.x || []
        this.ydata2 = (this.chart_data_2[4].data.y || []).map((n)=>{
          return Math.floor((n /100000000) * 100) / 100
        })
        this.loadChart2();
      }, 24000);
      setTimeout( ()=> {
        this.timeChangeOut = '15:00'
        this.xdata2 = this.chart_data_2[5].data.x || []
        this.ydata2 = (this.chart_data_2[5].data.y || []).map((n)=>{
          return Math.floor((n /100000000) * 100) / 100
        })
        this.loadChart2();
      }, 26000)
      setTimeout( ()=> {
        $("#card3").addClass("animate__fadeOut");
      }, 28000);
      setTimeout(() => {
        $("#card3").addClass("none");
        $("#card4").removeClass("none");
        this.$refs.endVideo.play()
      }, 29000);
      // 总共34秒
      if(this.device_id){
        setTimeout( ()=> {
          ApiServe.recordEnd({model_id:2,device_id:this.device_id,real:this.real})
        }, 39000);   //间隔
      }
    },
  },
};
</script>