import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as echarts from 'echarts';
import './assets/css/number.css'
import './assets/css/animate.css'
import './assets/css/animate.min.css'
import "./assets/css/index.css";
import "./assets/css/d3.css";
import "@/assets/css/kline.less";
import qs from 'qs'
import requests from './http/axios.js'   
import moment from 'moment'
import "vant/lib/index.css";

Vue.prototype.$axios = requests.requests  
Vue.prototype.$moment = moment
Vue.prototype.qs = qs           //全局注册，使用方法为:this.qs
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false;

import anime from 'animejs';
Vue.prototype.$anime = anime

import remAndPhoneCofig from "./utils/rem"
remAndPhoneCofig()

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
