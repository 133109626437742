import requests from '../http/axios'  
import axios from 'axios'

const url1 = process.env.VUE_APP_ONE
const url2 = process.env.VUE_APP_TWO
const url3 = process.env.VUE_APP_THREE

export default{
    // 获取模板页面的数据
    getData:(params)=>{
        return requests.requests.get(url1+'/api/video/videodata/',{params:params})
    },
    // 获取模板旁白的数据
    getVideoData:(params)=>{
        return requests.requests.get(url1+'/api/video/voicemake/',{params:params})
    },
    // 录屏服务开始
    recordStart:(params)=>{
        return requests.requests.get(url2+'/api/recordstart/',{params:params})
    },
    // 录屏服务结束
     recordEnd:(params)=>{
        return requests.requests.get(url2+'/api/recordprocess/',{params:params})
    },
    // 释放设备控制
    releasedSevice:(params)=>{
        return requests.requests.get(url2+'/api/releasedevice/',{params:params})
    },
    // 获取龙虎榜列表的数据
    getHbDepData:()=>{
        return requests.requests.get(url3,{params:{s: 'longhubang',
            f: 'getLHBSaleDepList',
            req: 'GetLHBSaleDepListReq',
            rsp: 'GetLHBSaleDepListRsp'}})
    },
    //通过日期得到k线
    getKLineByDate: (params) => {
        return requests.requests.post(`https://comm.wedengta.com/?s=kline&f=getKLineByDate&req=KLineDateReq&rsp=KLineDateRsp`,params);
    },
    //通过日期得到k线
    getSimpleQuote: (params) => {
        return requests.requests.post(`https://comm.wedengta.com/?s=quote&f=getSimpleQuote&req=QuoteReq&rsp=QuoteSimpleRsp`,params);
    },

    // 新的适配pc端录屏服务的模式
    getDataPC:(params)=>{
        return requests.requests.get('https://tanzhiw.com/api/video/videodata/',{params:params})
    },
    // 获取模板旁白的数据
    getVideoDataPC:(params)=>{
        return requests.requests.get('https://tanzhiw.com/api/video/voicemake/',{params:params})
    },
    recordstartPc:(domain,params)=>{
        return requests.requests.get(`${domain}/api/ffmpeg/recordstart/`,{params:params})
    },
    recordstopPc:(domain,params)=>{
        return requests.requests.get(`${domain}/api/ffmpeg/recordstop/`,{params:params})
    },
    combineaudioswithbgm:(domain,ip,port,data)=>{
        return requests.requests.post(`${domain}/api/ffmpeg/combineaudioswithbgm/?ip=${ip}&port=${port}`,data)
    },
    recordreleasePc:(domain,params)=>{
        return requests.requests.get(`${domain}/api/ffmpeg/recordrelease/`,{params:params})
    },
    releasepcportPc:(domainip,params)=>{
        return requests.requests.get(`https://tanzhiw.com/api/video/releasepcport/`,{params:params})
    },

    // 转发后端
    dtpostapiproxyPc:(newIpPort,url,params)=>{
        // newIpPort ip,port;url转发的url,params转发的参数
        const  urlParams1 = encodeURIComponent(url)
        const  urlParams2 = encodeURIComponent(JSON.stringify(params))
        return requests.requests.get(`${newIpPort}/dtpostapiproxy/?api=${urlParams1}&payloads=${urlParams2}`)
    },
    // 产业链标题摘要政策
    getAbstractPolicy: (params) => {
        return requests.requests.post('https://comm.mdengta.com/?s=IndustryChainMachineWrite&f=getTitleAbstractPolicy&req=GetTitleAbstractPolicyReq&rsp=GetTitleAbstractPolicyRsp',params,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
    },
    // 产业链投资的推荐
    getStockRecommend: (params) => {
        return requests.requests.post('https://comm.mdengta.com/?s=IndustryChainMachineWrite&f=getStockRecommend&req=GetStockRecommendReq&rsp=GetStockRecommendRsp',params,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
    },
    // 产业链图谱上下游
    getIndustryChain: (params) => {
        return requests.requests.post('https://comm.mdengta.com/?s=IndustryChainMachineWrite&f=getIndustryChain&req=GetIndustryChainReq&rsp=GetIndustryChainRsp',params,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
    },
    // 产业链音频获取
    getIndustryAudio: (newIpPort,url,params) => {
        const  urlParams1 = encodeURIComponent(url)
        const  urlParams2 = encodeURIComponent(JSON.stringify(params))
        return new Promise((resolve, reject) => {
            axios.get(`${newIpPort}/dtpostapiproxy/?api=${urlParams1}&payloads=${urlParams2}`,{
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(err =>{
                reject(err.data)
            })
        });
    },
}